// replace lazy css with angular service
// https://medium.com/better-programming/angular-multiple-themes-without-killing-bundle-size-with-material-or-not-5a80849b6b34

function createCssElement(fileName) {
    let link = document.createElement('link');

    link.id = 'tenantCss';
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = fileName;

    return link;
}

function createScriptElement(fileName, id) {
    let tag = document.createElement('script');

    tag.id = id;
    tag.type = 'text/javascript';
    tag.src = fileName;

    return tag;
}

function loadCss(tenant) {
    if (!document.getElementById('tenantCss')) {
        let head = document.getElementsByTagName('head')[0];
        let link = createCssElement('./' + tenant.toLocaleLowerCase() + '.css');

        link.onload = function () {
            removeLoader();
        }

        head.appendChild(link);
    }
}

function removeLoader() {
    let preloader = document.getElementById('preloader');
    let preloaderSpinner = document.getElementById('preloaderSpinner');

    if (preloader) {
        preloader.className += ' bg-hidden';
        preloaderSpinner.className += ' bg-hidden';

        preloader.remove();
        preloaderSpinner.remove();
    }
}

let lazyLoader = {
    loadCss: loadCss,
    removeLoader: removeLoader
};
